<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <label
      class="avoore-checkbox path d-block mr-2"
      v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
      for="select-all-inbox"
    >
      <input
        type="checkbox"
        class="d-inline-flex"
        v-model="selectAllInbox"
        id="select-all-inbox"
      />
      <svg viewBox="0 0 21 21">
        <path
          d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
        ></path>
      </svg>
    </label>

    <v-toolbar-title>
      {{ heading }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" style="top: -2px" class="ml-2">{{
            filterIsOn
          }}</v-icon>
        </template>
        <span>{{ $t("tooltip.filter-is-on") }}</span>
      </v-tooltip>
    </v-toolbar-title>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!enableAction"
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('reload', 'reload')"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>
    
    <v-menu
      v-if="$vuetify.breakpoint.smAndDown && !enableAction"
      v-model="showSearch"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          icon
          color="blue lighten-2"
          class="d-flex d-sm-none"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ iconSearch }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            :label="$t('label.title-or-sender-name')"
            outlined
            v-model="searchQuery"
            dense
            clearable
            hide-details="true"
          ></v-text-field>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="showSearch = false">{{
            $t("button.close")
          }}</v-btn>
          <v-btn color="primary" text @click="applyQuery">{{
            $t("button.search")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-menu bottom left v-if="!enableAction && !$vuetify.breakpoint.smAndDown">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconFilter }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.menu-filter") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in menus"
          :key="i"
          class="cursor-pointer memo-header-menu"
          :class="{
            'v-list-item--active success white--text':
              filterParams.only == item.slug,
          }"
          @click="menuAction(item.command, item.slug)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown && !enableAction">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-on="on"
          v-bind="attrs"
          v-model="searchQuery"
          :label="$t('label.search')"
          :placeholder="$t('label.memo-title-or-sender')"
          outlined
          dense
          clearable
          hide-details="true"
          class="ml-2 search-input"
          :class="{ 'min-w-300px': searchQuery }"
        ></v-text-field>
      </template>
      <span>{{ $t("tooltip.type-title-or-sender-name-then-enter") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiReload,
  mdiDotsVertical,
  mdiEmailSearchOutline,
  mdiFilterMenuOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiArchiveArrowDownOutline,
  mdiTuneVariant,
} from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";
export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
  },
  data: () => ({
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconSearch: mdiEmailSearchOutline,
    iconFilter: mdiFilterMenuOutline,
    iconEye: mdiEyeOutline,
    iconEyeClosed: mdiEyeOffOutline,
    iconArchive: mdiArchiveArrowDownOutline,
    menus: toolbarMenu.inboxFilterMenu,
    iconFilterActive: mdiTuneVariant,
    showSearch: false,
    searchQuery: "",
    timeout: null,
  }),
  computed: {
    ...mapState("inbox", ["filterParams"]),
    filterIsOn() {
      if (!this.filterParams.only) return null;
      return this.iconFilterActive;
    },
    selectAllInbox: {
      get() {
        return this.$store.state.inbox.selectAllInbox;
      },
      set(e) {
        this.$store.commit("inbox/SET_SELECT_ALL_INBOX", e);
      },
    },
  },
  watch: {
    searchQuery(val) {
      if (val && val.length < 3) return;

      let params = this.filterParams;

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        params.filter = val ? val : "";
        this.loadIncoming(params);
      }, 1000);
    },
  },
  methods: {
    ...mapActions(["setMessageBox"]),
    ...mapActions("inbox", ["loadIncoming", "doSelectAllInbox"]),
    selectAll(e) {
      this.doSelectAllInbox(e.target.checked);
    },
    applyQuery() {
      this.showSearch = false;
      if (!this.searchQuery || this.searchQuery == "") return;
      let params = this.filterParams;
      params.filter = this.searchQuery;
        this.loadIncoming(params);
    },
    menuAction(command, slug) {
      this.$emit("menuAction", { command: command, slug: slug });
    },
  },
};
</script>